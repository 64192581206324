var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseSimpleDataTable',{attrs:{"headers":_vm.filteredHeaders,"items":_vm.filteredVwLettersToPrints,"loading":_vm.loading,"search":"","selected":_vm.selected,"item-key":"DocumentId","items-per-page":_vm.itemsPerPage,"footerProps":{ itemsPerPageOptions: [5, 10, 15, 25, 50, 100] },"show-select":"","checkbox-color":"primary"},on:{"update:selected":function($event){_vm.selected=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"prependParams",fn:function(){return [_c('v-row',{staticClass:"ma-3"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.selected.length == 0},on:{"click":_vm.markAsPrinted}},[_vm._v("Mark Selected As Printed")])],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.selected.length == 0 || !_vm.selectedArePdfOrWord},on:{"click":_vm.downloadForPrint}},[_vm._v("Get Selected For Printing")])],1)],1)]},proxy:true},{key:"appendParams",fn:function(){return undefined},proxy:true},{key:"item.ApplicationId",fn:function({ item }){return [(item.ApplicationId)?_c('BaseTooltipButton',{attrs:{"small":"","iconColor":"primary","icon":"mdi-file-document"},on:{"click":function($event){return _vm.$router.push({
          name: 'staffForm',
          params: { appId: item.ApplicationId },
        })}}},[_vm._v("Go To Application ")]):_vm._e()]}},{key:"item.DocumentId",fn:function({ item }){return [_c('BaseTooltipButton',{attrs:{"small":"","iconColor":"primary","icon":"mdi-download"},on:{"click":function($event){return _vm.downloadDocument(item.DocumentId)}}},[_vm._v("Download ")]),_c('BaseTooltipButton',{attrs:{"small":"","iconColor":"primary","icon":"mdi-printer"},on:{"click":function($event){return _vm.printDocument(item.DocumentId)}}},[_vm._v("Print ")])]}},{key:"item.FileName",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(item.FileName)+" "),(
          item.MimeType != 'application/pdf' &&
          item.MimeType !=
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        )?_c('BaseTooltipIcon',{attrs:{"icon":"mdi-alert","color":"orange"}},[_vm._v("Letter Can Not Be Merged")]):_vm._e()],1)]}},{key:"item.CreatedDate",fn:function({ item }){return [_vm._v(_vm._s(_vm._f("formatDateTime")(item.CreatedDate)))]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }