<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1 class="text-h4 primary--text">Letters to Print</h1>
      </v-col>
      <v-col cols="12">
        <VwLettersToPrintGrid></VwLettersToPrintGrid>
      </v-col>
    </v-row> </v-container
></template>
<script>
import VwLettersToPrintGrid from '@components/admin/VwLettersToPrintGrid'
export default {
  //model: {
  //	prop: ''
  //},
  props: {},
  components: { VwLettersToPrintGrid },
  data() {
    return {}
  },
  computed: {},
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
