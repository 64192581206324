<template>
  <BaseSimpleDataTable
    :headers="filteredHeaders"
    :items="filteredVwLettersToPrints"
    :loading="loading"
    search
    :selected.sync="selected"
    item-key="DocumentId"
    :items-per-page.sync="itemsPerPage"
    :footerProps="{ itemsPerPageOptions: [5, 10, 15, 25, 50, 100] }"
    show-select
    checkbox-color="primary"
  >
    <template v-slot:prependParams>
      <v-row class="ma-3">
        <v-col cols="12" md="3">
          <v-btn
            color="primary"
            @click="markAsPrinted"
            :disabled="selected.length == 0"
            >Mark Selected As Printed</v-btn
          >
        </v-col>
        <v-col cols="12" md="3">
          <v-btn
            color="primary"
            @click="downloadForPrint"
            :disabled="selected.length == 0 || !selectedArePdfOrWord"
            >Get Selected For Printing</v-btn
          >
        </v-col>
      </v-row>
    </template>
    <template v-slot:appendParams> </template>
    <template v-slot:item.ApplicationId="{ item }">
      <BaseTooltipButton
        v-if="item.ApplicationId"
        small
        iconColor="primary"
        icon="mdi-file-document"
        @click="
          $router.push({
            name: 'staffForm',
            params: { appId: item.ApplicationId },
          })
        "
        >Go To Application
      </BaseTooltipButton>
    </template>
    <template v-slot:item.DocumentId="{ item }">
      <BaseTooltipButton
        small
        @click="downloadDocument(item.DocumentId)"
        iconColor="primary"
        icon="mdi-download"
        >Download
      </BaseTooltipButton>
      <BaseTooltipButton
        small
        @click="printDocument(item.DocumentId)"
        iconColor="primary"
        icon="mdi-printer"
        >Print
      </BaseTooltipButton>
      <!-- :href="`api/Document/Download/${item.DocumentId}`" -->
      <!-- target="_blank" -->
    </template>
    <template v-slot:item.FileName="{ item }"
      ><span>
        {{ item.FileName }}
        <BaseTooltipIcon
          v-if="
            item.MimeType != 'application/pdf' &&
            item.MimeType !=
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          "
          icon="mdi-alert"
          color="orange"
          >Letter Can Not Be Merged</BaseTooltipIcon
        >
      </span>
    </template>
    <template v-slot:item.CreatedDate="{ item }">{{
      item.CreatedDate | formatDateTime
    }}</template>
  </BaseSimpleDataTable>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
export default {
  components: {},
  data: () => ({
    selected: [],
    itemsPerPage: 50,
  }),
  created() {
    this.loadVwLettersToPrints()
  },
  computed: {
    ...get('vwLettersToPrint', ['vwLettersToPrints', 'loading']),
    filteredVwLettersToPrints() {
      var list = this.vwLettersToPrints.filter((i) => true)
      return list
    },
    selectedArePdfOrWord() {
      let valid = true
      this.selected.forEach((i) => {
        if (
          i.MimeType != 'application/pdf' &&
          i.MimeType !=
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
          valid = false
        }
      })
      return valid
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          sortable: true,
          value: 'ApplicationId',
          visible: true,
        },
        {
          text: '',
          align: 'center',
          sortable: true,
          value: 'DocumentId',
          visible: true,
        },
        {
          text: 'Application Number',
          align: 'center',
          sortable: true,
          value: 'ApplicationNumber',
          visible: true,
        },
        {
          text: 'File Name',
          align: 'center',
          sortable: true,
          value: 'FileName',
          visible: true,
        },

        {
          text: 'First Name',
          align: 'center',
          sortable: true,
          value: 'FirstName',
          visible: true,
        },
        {
          text: 'M.I.',
          align: 'center',
          sortable: true,
          value: 'MiddleInitial',
          visible: true,
        },
        {
          text: 'Last Name',
          align: 'center',
          sortable: true,
          value: 'LastName',
          visible: true,
        },
        {
          text: 'Mailing Address 1',
          align: 'center',
          sortable: true,
          value: 'MailingAddress1',
          visible: true,
        },
        {
          text: 'Mailing Address 2',
          align: 'center',
          sortable: true,
          value: 'MailingAddress2',
          visible: true,
        },
        {
          text: 'City',
          align: 'center',
          sortable: true,
          value: 'City',
          visible: true,
        },
        {
          text: 'State',
          align: 'center',
          sortable: true,
          value: 'State',
          visible: true,
        },
        {
          text: 'Zip',
          align: 'center',
          sortable: true,
          value: 'Zip',
          visible: true,
        },
        {
          text: 'Created Date',
          align: 'center',
          sortable: true,
          value: 'CreatedDate',
          visible: true,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('vwLettersToPrint', ['loadVwLettersToPrints']),
    ...call('document', [
      'deleteDocument',
      'downloadDocument',
      'printDocument',
      'markSelectedAsPrinted',
      'downloadSelected',
    ]),
    async markAsPrinted() {
      let ids = this.selected.map((i) => i.DocumentId)
      let payload = { ids }
      try {
        let res = await this.markSelectedAsPrinted(payload)
        this.loadVwLettersToPrints()
      } catch (err) {}
    },
    async downloadForPrint() {
      let ids = this.selected.map((i) => i.DocumentId)
      let payload = { ids }
      try {
        let res = await this.downloadSelected(payload)
      } catch (err) {}
    },
  },
}
</script>
<style></style>
